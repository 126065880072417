import {httpClient} from "@/libs/http";
import {debounce} from "lodash/function";
import {IMAGE_OPTIONS, RECEIPT_DETAIL_STATUS_OPTIONS} from "@/libs/const";

export default {
    data: () => ({
        page: 1,
        totalPage: 1,
        items: [],
        updatingItem: {},
        filters: {},
        sort: {},
        imageOptions: [...IMAGE_OPTIONS],
        statusOptions: [...RECEIPT_DETAIL_STATUS_OPTIONS],
        checkDialog: false,
        isLoading: false,
        viewOption: 'view0',
        sum: {},
        dialogReceiptFast: false,
        dialogReceiptHasStamp: false,
        qrScanDialog: false,
        qrScanType: null,
    }),
    watch: {
        page() {
            this.getList(false)
        },
        filters: {
            handler() {
                this.page = 1
                this.getList(true)
            },
            deep: true
        }
    },
    mounted() {
        this.getList(false)
        // this.getActiveStamp()
    },
    methods: {
        setViewOption(mode) {
            this.viewOption = mode
        },
        showQRCodeScan(type) {
            this.qrScanDialog = true
            this.qrScanType = type
        },
        hideQRCodeScan() {
            this.qrScanDialog = false
            this.qrScanType = null
        },
        onQRScanned(filter) {
            this.filters = {...this.filters, [filter.name]: filter.value}
            this.getList()
        },
        getStorageLocation(locations) {
            if (!locations) {
                return ''
            }
            const locationSplit = locations.split(';')
            return locationSplit.join('<br>')
        },
        getList: debounce(function(showItem = false) {
            httpClient.post('/receipt-waiting-list', {...this.filters, page: this.page}).then(({data}) => {
                this.totalPage = data.totalPage
                this.items = [...data.rows]
                this.sum = {...data.sum}
                if (this.items && this.items.length === 1 && showItem) {
                    const item = this.items[0]
                    if (!item.status && !this.isDisabledBtnCheck(item)) {
                        this.showCheckDialog(item)
                    }
                }
            })
        }, 1000),
        onFilterChange(filter) {
            this.filters = {...this.filters, [filter.name]: filter.value}
        },
        onSortChange(sort) {
            // this.sort = {...this.sort, [sort.name]: sort.value}
            // this.sort = {...sort}
            this.filters = {...this.filters, ...sort}
        },
        async exportExcel() {
            if (this.isLoading) {
                this.$vToastify.warning(this.$t('messages.waiting_file_download'))
                return false
            }
            this.isLoading = true
            const filename = 'nhap-kho-kiem-dem.xlsx'

            try {
                await this.downloadExcelFile('/receipt-waiting-export', {...this.filters}, filename)
                this.isLoading = false
            } catch (e) {
                this.isLoading = false
            }
        },
        showCheckDialog(item) {
            if (this.isDisabledBtnCheck(item)) {
                this.$vToastify.error(this.$t('labels.no_permission_process'))
                return false
            }
            this.checkDialog = true
            this.updatingItem = {...item}
        },
        hideCheckDialog() {
            this.checkDialog = false
            this.updatingItem = {}
        },
        isDisabledBtnStatus(item) {
            if (!item || !item.id_employee || !window.me || window.me.employee.id !== item.id_employee) {
                return true
            }
            return false
        },
        isDisabledBtnCheck(item) {
            if (!item || (item.id_employee && window.me.employee.id !== item.id_employee && item.request_quantity < 50) || item.has_stamp) {
                return true
            }
            return false
        },
        async updateStatus(item, type) {
            if (this.isDisabledBtnStatus(item)) {
                this.$vToastify.error(this.$t('labels.no_permission_process'))
                return false
            }
            if (!confirm(this.$t('messages.are_you_sure'))) {
                return false
            }
            if (this.isLoading) {
                this.$vToastify.warning(this.$t('messages.loading'));
                return false
            }
            this.isLoading = true

            try {
                await httpClient.post('/receipt-waiting-update-status', {
                    id: item.id,
                    type
                })
                this.isLoading = false
                this.getList(false)
                this.$root.$emit('playSuccessAudio')
            } catch (e) {
                const errMsg = e.response && e.response.data && e.response.data.error && e.response.data.error.message || null
                this.$vToastify.error(errMsg);
                this.isLoading = false
                this.$root.$emit('playErrorAudio')
            }
        },
        async getActiveStamp() {
            try {
                const {data} = await httpClient.post('/receipt-waiting-printed')
                if (data && data.count_id && data.count_id > 0 && data.insert_tracking) {
                    this.filters = {...this.filters, sku: data.sku}
                }
            } catch (e) {
                const errMsg = e.response && e.response.data && e.response.data.error && e.response.data.error.message || null
                this.$vToastify.error(errMsg);
            }
        },
        showReceiptFast() {
            this.dialogReceiptFast = true
        },
        hideReceiptFast() {
            this.dialogReceiptFast = false
        },
        showReceiptHasStamp() {
            this.dialogReceiptHasStamp = true
        },
        hideReceiptHasStamp() {
            this.dialogReceiptHasStamp = false
        },
    },
}
